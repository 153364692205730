import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';


export const sliderInit = () => {

  const sliderInfo = document.querySelector('.section_info_carousel__slider');
  if (typeof (sliderInfo) != undefined && typeof (sliderInfo) != null && sliderInfo != null && typeof (sliderInfo) != 'undefined') {
    const sliderInfo = tns({
      container: '.section_info_carousel__slider',
      items: 1,
      slideBy: 1,
      loop: false,
      controls: false,
      mouseDrag: true,
      nav: true,
      navPosition: "bottom",
      gutter: 0,
      responsive: {
        500: {
          gutter: 40,
        },
        750: {
          items: 2,
          slideBy: 1,
          gutter: 40,
        },
        1200: {
          items: 1,
          slideBy: 1,
        },
        1400: {
          items: 2,
          slideBy: 1,
        }
      }
    });
  }

  const sliderPosts = document.querySelector('.wyspakultury__slider');
  if (typeof (sliderPosts) != undefined && typeof (sliderPosts) != null && sliderPosts != null && typeof (sliderPosts) != 'undefined') {
    const sliderPosts = tns({
      container: '.wyspakultury__slider',
      items: 1,
      slideBy: 1,
      loop: false,
      controls: false,
      mouseDrag: true,
      nav: true,
      navPosition: "bottom",
      gutter: 0,
      edgePadding: 30,

      gutter: 20,
      responsive: {
        500: {
          gutter: 40,
         
        },
        750: {
          items: 2,
          slideBy: 1,
          gutter: 40,
          edgePadding: 80,
        },
        1300: {
          items: 3,
          slideBy: 1,
          
        },
        1500: {
          items: 4,
          slideBy: 1,
          
        }
      }
    });
  }

}