import {navInit} from './scripts/navigation';
import {sliderInit} from './scripts/sliders';

sliderInit();


// var MenuSpy = require('menuspy');

// var elm = document.querySelector('#menu-header-menu');
// var ms = new MenuSpy(elm);
 
// import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
// import 'animate.css/animate.css';

// ..
// AOS.init({
//   offset: 100, // offset (in px) from the original trigger point
//   delay: 50, // values from 0 to 3000, with step 50ms
//   duration: 500, // values from 0 to 3000, with step 50ms
//   easing: 'ease-in-out', // default easing for AOS animations
//   once: true, // whether animation should happen only once - while scrolling down
//   mirror: false,
// }); 

navInit();

jQuery(document).ready(function ( $ ) {

  // smooth scroll to ID
  $('a[href*="#"]').on('click', function (e) {
    e.preventDefault();

    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top - 50 // choose what you need
    }, 500, 'linear');
  });

  // const navbar = $('header.header')
  // // const nav = $('.header__navbar');

  // $(window).scroll(function() {    
  //   let scroll = $(window).scrollTop();
  //   let windowHeight = $(window).height();
    
  //   if (scroll >= 50) {
  //     navbar.addClass("scrolled");
  //     // nav.addClass("scrolled");
  //   } else {
  //     navbar.removeClass("scrolled");
  //     // nav.removeClass("scrolled");
  //   }
   
  // });
});

//checkout dynamically show company data
const companyCheckbox = document.getElementById("company-expense-checkbox");
if (companyCheckbox) {
  companyCheckbox.addEventListener("click", () => {
    if (companyCheckbox.checked) {
      document.querySelector(".company-data").style.display = "block";
    } else {
      document.querySelector(".company-data").style.display = "none";
    }
  });
}
