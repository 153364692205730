export function navInit() {
    jQuery(document).ready(function ($) {
        //obsługa hamburgera i elementów menu
        "use strict";
        $('.c-hamburger').on('click', function () {
            ($('.header__navBox').hasClass("is-active")) ? $('.header__navBox').removeClass("is-active"): $('.header__navBox').addClass("is-active");
            ($('.c-hamburger').hasClass("is-active")) ? $('.c-hamburger').removeClass("is-active"): $('.c-hamburger').addClass("is-active");
            ($('.header__navBox').hasClass("is-active")) ? $('#logo_mobilne').html('<img src="/img/logo_czarne_mob.png" alt="">'): $('#logo_mobilne').html('<img src="/img/logo_biale_mob.png" alt="">');
            ($('.header__navBox .menu li.pll-parent-menu-item .sub-menu')).hasClass("is-active") ? $('.header__navBox .menu li.pll-parent-menu-item .sub-menu').removeClass("is-active"): $('.header__navBox .menu li.pll-parent-menu-item .sub-menu');
        });
    });
}